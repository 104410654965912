$fa-font-path: '../fontawesome-pro/webfonts';
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import '~bootstrap/scss/bootstrap';

@import './_variables.scss';
@import './_mixins.scss';
@import './_header.scss';
@import './_footer.scss';

html {
  font-size: 17px;
}

body {
  font-weight: 300;
  font-family: 'museo-sans', sans-serif;
  background-color: $think7_bg_white;
}

main {
  @include media-breakpoint-up(md) {
    margin: 6rem 0 3rem;
  }
  margin: 3rem 0;
}

img {
  width: 100%;
  height: auto;
}

h1 {
  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }
}

h2 {
  font-size: 1.25rem;

  &.section-heading {
    text-transform: uppercase;
    position: relative;
    margin-left: 0.2rem;
    margin-bottom: 2rem;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      width: 2px;
      transform: rotate(15deg);
      background-color: $cigi-primary-colour;
      transform-origin: center;
    }

    &:not(:first-child) {
      margin-top: 3rem;
    }
  }
}

h3 {
  font-size: 1.125rem;
}

a {
  transition: color 0.2s ease;
  color: $black;

  &:hover {
    text-decoration: none;
    color: $cigi-primary-colour;
  }
}

.body {
  a {
    color: $cigi-primary-colour;
    text-decoration: underline;

    &:hover {
      color: $cigi-dark-pink;
    }
  }
}

section {
  margin: 3rem 0;
}
