footer {
  margin-top: 6rem;
  padding: 3rem 0 5rem;
  background-color: $think7_bg_red;
  color: $white;
  text-align: center;
  font-size: 1rem;

  a {
    color: $white;
    padding: 0.2em 0.4em;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    
    &:hover {
      color: $white;
      text-decoration: underline;
      background-color: rgba(255, 255, 255, 0.1); /* light translucent white */
    }
  }

  .logos {
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    a {
      @include media-breakpoint-down(sm) {
        width: 50%;
        margin-bottom: 1rem;
      }
      padding: 1rem 1rem;
      margin: 0 0.5rem;
    }

    svg {
      path {
        fill: $white;
      }
    }

    img,
    svg {
      height: 60px;
      width: auto;
    }

    .g7-logo {
      height: 65px;
    }
  }

  .email {
    font-size: 1rem;
    margin: 2rem 0;
  }

  .email-list {
    form {
      display: flex;
      justify-content: center;

      input[type='email'] {
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-left: 0;
        }
        background-color: $think7_bg_red;
        border: 0;
        border-bottom: 3px solid white;
        margin-left: 3em;
        margin-right: 0.5rem;
        width: 60%;
        padding-left: 0.25rem;

        &::placeholder {
          color: $cigi-medium-light-grey;
        }
      }

      input[type='submit'] {
        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
        margin-right: 3em;
        padding: 0.25rem 1rem;
        border: 1px solid $white;
        border-radius: 2rem;
        transition: background-color ease 0.2s;

        &:hover {
          background-color: $cigi-primary-colour;
          color: $white;
        }
      }
    }

    .response-message {
      opacity: 0;
      transition: opacity ease 0.2s;
      border: 1px solid $white;
      border-radius: 5px;
      color: $white;
      padding: 0.5rem 1rem;
      margin-top: 1rem;

      &.show {
        opacity: 1;
      }
    }
  }

  .archive-link {
    @include media-breakpoint-up(sm) {
      margin-left: 3rem;
      margin-right: 3rem;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 5rem;
      margin-right: 5rem;
    }
    margin-bottom: 2rem;

    a {
      text-decoration: underline;
    }
  }

  nav {
    margin: 2rem 0;

    ul {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        position: relative;
        padding: 0.5rem 1rem 0.5rem;
        text-transform: uppercase;
        font-size: 0.875rem;
        color: $cigi-medium-light-grey;

        &:not(:last-child) {
          &::after {
            @include media-breakpoint-down(sm) {
              content: none;
            }
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 1px;
            transform: rotate(15deg);
            background-color: lightgray;
            transform-origin: center;
          }
        }

        &.submit-abstract {
          button {
            border: 2px solid $cigi-primary-colour;
            border-radius: 5px;
            padding: 0.5rem 1rem;
            transition: background-color 0.3s ease, color 0.3s ease;
            text-transform: uppercase;
          }

          &:hover {
            button {
              background-color: $cigi-primary-colour;
              color: $white;
            }
          }
        }
      }
    }
  }
}
